import {getUser} from '@/graphql/queries';
import {useQuery} from '@apollo/client';

import {fbAuth} from '@/lib/firebase';

/*
 * The benefit of using these hooks is to bypass the context,
 * which we want to remove eventually.
 */

export const useUserName = () => {
  const fbUser = fbAuth.currentUser;

  const fbFirstName = fbUser?.displayName?.split(' ')?.[0];
  const skipFetchingName = !!fbFirstName || fbAuth.currentUser?.isAnonymous;
  const {data} = useQuery(getUser, {
    fetchPolicy: 'cache-first',
    skip: skipFetchingName !== undefined ? skipFetchingName : true,
  });

  return data?.getUser?.firstName || fbFirstName;
};

export const useUserEmail = () => {
  const fbUser = fbAuth.currentUser;

  const skipFetchingEmail = !!fbUser?.email || fbAuth.currentUser?.isAnonymous;
  const {data} = useQuery(getUser, {
    fetchPolicy: 'cache-first',
    skip: skipFetchingEmail !== undefined ? skipFetchingEmail : true,
  });

  return data?.getUser?.email || fbUser?.email;
};
