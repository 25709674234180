import Image from 'next/image';
import {classNames} from 'primereact/utils';

import {Button, Icon} from '@kasta-io/components';

import useIsTargetMedia from '@/hooks/useIsTargetMedia';

import SEOHead from '@/components/heads/SEOHead';
import MainButton from '@/components/ui/buttons/MainButton/MainButton';
import ZenDeskChatButton from '@/components/ui/buttons/ZenDeskChatButton/ZenDeskChatButton';

import s from './AuthenticationLayout.module.scss';
import { useRouter } from 'next/router';
import { WEBPAGE_URL } from '@/constants';

type AuthenticationLayoutProps = {
  isLeftAligned?: boolean;
  isBackEnabled?: boolean;
  onBack?: () => void;
  pageTitle?: string;
  children: React.ReactNode;
};

export default function AuthenticationLayout({
  pageTitle = 'Ka. Web App',
  isLeftAligned,
  isBackEnabled,
  onBack,
  children,
}: AuthenticationLayoutProps) {
  const isMobileDevice = useIsTargetMedia('tablet', 'less');
  const {push} = useRouter();

  const headerClassnames = classNames(s.header, {
    [s.leftAligned]: isLeftAligned,
  });

  const redirectToKaApp = () => {
    push(WEBPAGE_URL);
  };

  return (
    <>
      <SEOHead pageTitle={pageTitle} />

      <div className={s.container}>
        <div className={s.content}>
          <div className={s.wrapper}>
            <header className={headerClassnames} role="onboarding-header">
              {isBackEnabled && (
                <div role="back-button">
                  <MainButton
                    size="ICON"
                    leftIcon="chevron-left"
                    color="PRIMARY"
                    iconFontSize={isMobileDevice ? 22 : 26}
                    variant="SECONDARY"
                    onClick={onBack}
                  />
                </div>
              )}

              <Button
                ghost
                noPadding
                type='secondary-icon'
                onClick={redirectToKaApp}>
                <Icon
                  name="kasta"
                  size={isMobileDevice ? 40 : 70}
                />
              </Button>

              <ZenDeskChatButton />
            </header>

            {children}
          </div>
        </div>

        <aside className={s.aside} aria-hidden>
          <Image
            alt=""
            className={s.img}
            fill
            sizes="40vw"
            priority
            quality={100}
            src={'/images/slide.png'}
          />
        </aside>
      </div>

      <footer className={s.footer}></footer>
    </>
  );
}
