import {useCallback, useEffect, useState} from 'react';
import {Button} from 'primereact/button';
import {classNames} from 'primereact/utils';

import {Icon} from '@kasta-io/components';

import {ERROR_LEVEL, THREE_SECOND} from '@/constants';
import useIsTargetMedia from '@/hooks/useIsTargetMedia';
import {zendeskService} from '@/services/support/ZendeskService';
import {tracking} from '@/services/tracking/TrackingService';
import {handleContactSupport} from '@/utils/support';

import s from './ZenDeskChatButton.module.scss';

declare global {
  interface Window {
    zE: any;
  }
}

type COLOR = keyof typeof COLORS;

const BACKGROUND_COLOR_STYLES = {
  WHITE: s.backgroundColorPaper,
  DEFAULT: '',
};

const COLORS = {
  WHITE: 'var(--colors-text-onPrimary)',
  PRIMARY: 'var(--colors-main-primary)',
  SECONDARY: 'var(--colors-main-secondary)',
  TEXT: 'var(--colors-text-onBackgroundMedium)',
};

export default function ZenDeskChatButton({color = 'PRIMARY'}: {color?: COLOR}) {
  const [unreadMessagesCount, setUnreadMessagesCount] = useState<string | null>(null);
  const isMobileDevice = useIsTargetMedia('tablet', 'less');
  const isDesktop = useIsTargetMedia('desktop', 'more');

  function messageCountCallback(count: number) {
    if (count > 0) {
      setUnreadMessagesCount(count > 99 ? '99+' : `${count}`);
    }
  }

  const getUnreadMessagesCount = useCallback(() => {
    try {
      zendeskService.getUnreadMessagesCount(messageCountCallback);
    } catch (error: any) {
      tracking.logError({
        error_message: error.message ? `${error.message}` : JSON.stringify(error),
        error_level: ERROR_LEVEL.NOTICE,
        error_message_id: 'zendesk/kasta/unable-to-get-unread-message-count',
      });
    }
  }, []);

  useEffect(() => {
    setTimeout(() => {
      getUnreadMessagesCount();
    }, THREE_SECOND);
  }, [getUnreadMessagesCount]);

  const handleOnClick = useCallback(() => {
    setUnreadMessagesCount(null);
    handleContactSupport();
  }, []);

  const bgColor = isDesktop ? 'WHITE' : 'DEFAULT';
  const buttonClassnames = classNames(s.button, [BACKGROUND_COLOR_STYLES[bgColor]]);

  return (
    <div role="zendesk-chat-button">
      <Button
        type="button"
        aria-label="zendesk-chat-button"
        onClick={handleOnClick}
        className={buttonClassnames}>
        <Icon name="chat" size={isMobileDevice ? 24 : 28} color={COLORS[color] as COLOR} />
        {unreadMessagesCount && <span className={s.countBadge}>{unreadMessagesCount}</span>}
      </Button>
    </div>
  );
}
