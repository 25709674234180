import Head from 'next/head';

interface SEOHeadProps {
  pageTitle?: string;
  description?: string;
  image?: string;
  path?: string;
  themeColor?: string;
  schemaData?: string;
}

// TODO: set img, rootUrl, path, name, url and logo
const defaultSeo = {
  siteName: 'Ka. Web App',
  description:
    'Ka is a crypto payment app that allows you to quickly and easily send, receive, and convert crypto.',
  img: '',
  content: '',
  type: 'website',
  rootUrl: '',
  path: '',
};

export default function SEOHead({
  pageTitle = 'Ka. Web App',
  description,
  image,
  path,
  schemaData,
}: SEOHeadProps) {
  const url = defaultSeo.rootUrl + path;

  const themeColorValue = '#f6f6f6';

  return (
    <Head>
      <title>{pageTitle}</title>

      <meta name="description" content={description || defaultSeo.description} />
      {/*{canonical && <link rel="canonical" key="$canonical" href={canonical} />}*/}

      <meta name="theme-color" content={themeColorValue} />

      {/* <!-- Google / Search Engine Tags --> */}
      <meta itemProp="name" content={pageTitle} />
      <meta itemProp="description" content={description || defaultSeo.description} />
      <meta itemProp="image" content={image || defaultSeo.img} />

      {/* <!-- Twitter Meta Tags --> */}
      <meta name="twitter:title" content={pageTitle} />
      <meta name="twitter:creator" content="@ka_app" />
      <meta name="twitter:description" content={description || defaultSeo.description} />
      <meta name="twitter:image" content={image || defaultSeo.img} />
      {/*<meta name="twitter:image" content="https://yourdomain.com/icons/android-chrome-192x192.png" />*/}
      <meta name="twitter:card" content="summary_large_image" />

      {/* Facebook Meta Tags */}
      <meta property="og:type" content="website" />
      <meta property="og:title" content={pageTitle} />
      <meta property="og:site_name" content="Ka. Web App" />
      <meta property="og:description" content={description || defaultSeo.description} />
      {/*<meta property="og:image" content="https://yourdomain.com/icons/apple-touch-icon.png" />*/}
      <meta property="og:image" content={image || defaultSeo.img} />
      <meta property="og:url" content={url} />
      <meta property="og:type" content={defaultSeo.type} />

      {/* SCHEMA DATA FOR CURRENT PAGE PAGE */}
      {schemaData && <script type="application/ld+json" dangerouslySetInnerHTML={{__html: schemaData}} />}
    </Head>
  );
}
