import type {Countries, ISOCountryCode} from '@/types/business';

export type CountryList = {
  name: string;
  code: string;
  iso: string;
  image: string;
};

export const getCountriesPhoneCodeList = (countries: Countries) => {
  if (!countries) return [];

  const list = Object.entries(countries)
    .filter(([_, country]) => Boolean(country.callingCode.length))
    .map(([ISOCode, country]) => ({
      name: country.name.common,
      code: '+' + country.callingCode[0],
      iso: ISOCode,
      image: country.flag,
    }))
    .sort((a, b) => a.name.localeCompare(b.name));

  return list;
};

export const getCountry = (code: ISOCountryCode, countries: Countries) => {
  if (countries && code in countries) {
    return countries[code as keyof typeof countries];
  }
  return null;
};

export const formatFinalCallingCode = (code: ISOCountryCode, countries: Countries) => {
  if (!countries) return '';
  const country = countries[code];
  const callingCode = country?.callingCode?.[0] ? `+${country?.callingCode?.[0]}` : '';
  return callingCode;
};
