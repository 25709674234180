import {useCallback, useState} from 'react';
import {useRouter} from 'next/router';

import {createContext, useContext} from 'use-context-selector';

import {BaseAuthenticationMethod} from '@/services/auth/BaseAuthenticationMethod';
import {createResendCooldownDate} from '@/utils/time';

import type {ConfirmVerificationCodeOptions, LoginSteps, SendVerificationCodeOptions} from '@/types/business';
import type {Dispatch, ReactNode, SetStateAction} from 'react';

type LoginProcessType = {
  // Control flow
  loginStep: LoginSteps;
  setLoginStep: Dispatch<SetStateAction<LoginSteps>>;
  // Countdown
  resendCooldownEnd: string | undefined;
  setResendCooldownEnd: Dispatch<SetStateAction<string | undefined>>;
  // Verification wrappers
  sendVerificationCode: (options: SendVerificationCodeOptions) => Promise<void>;
  confirmVerificationCode: (props: ConfirmVerificationCodeOptions) => Promise<void>;
  // General
  goToSignUp: () => void;
  goToSignIn: () => void;
  goToVerifyCode: () => void;
  goToEnterUserPin: () => void;
};

export const LoginProcess = createContext<LoginProcessType>({
  loginStep: 'enterPhoneOrEmail',
  setLoginStep: () => {},
  resendCooldownEnd: undefined,
  setResendCooldownEnd: () => {},
  sendVerificationCode: async () => {},
  confirmVerificationCode: async () => {},
  goToSignUp: () => {},
  goToSignIn: () => {},
  goToVerifyCode: () => {},
  goToEnterUserPin: () => {},
});

export const useLoginContext = () => useContext(LoginProcess);

const LoginProcessProvider = ({
  loginStep,
  setLoginStep,
  children,
}: {
  loginStep: LoginSteps;
  setLoginStep: Dispatch<SetStateAction<LoginSteps>>;
  children: ReactNode;
}) => {
  //=============================================================================
  // General states and elements
  //=============================================================================

  const router = useRouter();
  const [resendCooldownEnd, setResendCooldownEnd] = useState<string>();

  const sendVerificationCode = useCallback(
    async ({
      authService,
      onBlockUser,
      operation = BaseAuthenticationMethod.OPERATION.SIGN_IN,
      blockUserOn = BaseAuthenticationMethod.THROW_ON.USER_NOT_FOUND,
    }: SendVerificationCodeOptions) => {
      let shouldResumeSignUp = false;
      let shouldBlock = false;

      const identifier = authService.getIdentifier();

      try {
        await authService.validateIdentifier({
          identifier,
          throwOn: blockUserOn,
        });
      } catch (error: unknown) {
        authService.handleAuthenticationError({
          error,
          shouldThrow: true,
          // Useful in the login flow.
          onUserNotFound: () => {
            setLoginStep('userNotFound');
            onBlockUser?.();
            shouldBlock = true;
          },
          // Useful in the add-phone-number flow.
          onUserAlreadyExists: () => {
            onBlockUser?.();
            shouldBlock = true;
          },
          // Useful to resume sign up from the login flow.
          onUserStatusError: () => {
            shouldResumeSignUp = true;
          },
        });
      }

      if (shouldBlock) {
        return;
      }

      await authService.sendVerificationMechanism({operation, identifier});
      const cooldownExpireDate = createResendCooldownDate(authService.COOLDOWN_TIME);

      if (shouldResumeSignUp) {
        const params = new URLSearchParams({
          email: identifier!,
          resendCooldownEnd: cooldownExpireDate.toISOString(),
        }).toString();

        router.push(`/signup/email-sent?${params}`);
        return;
      }

      setResendCooldownEnd(cooldownExpireDate.toISOString());
      setLoginStep('verifyCode');
    },
    [router, setLoginStep],
  );

  const confirmVerificationCode = useCallback(async ({code, authService}: ConfirmVerificationCodeOptions) => {
    const identifier = authService.getIdentifier();
    // After confirmation, fbUser changed to a non-anonymous user will trigger /login useEffect
    await authService.validateVerificationMechanism({code, identifier});
  }, []);

  //=============================================================================
  // Navigation
  //=============================================================================

  const goToSignUp = useCallback(() => {
    router.push('/signup');
  }, [router]);

  const goToSignIn = useCallback(() => {
    router.push('/login');
    setLoginStep('enterPhoneOrEmail');
  }, [router, setLoginStep]);

  const goToVerifyCode = useCallback(() => {
    setLoginStep('verifyCode');
  }, [setLoginStep]);

  const goToEnterUserPin = useCallback(() => {
    setLoginStep('enterUserPin');
  }, [setLoginStep]);

  return (
    <LoginProcess.Provider
      value={{
        // Flow control
        loginStep,
        setLoginStep,
        // 2-min countdown
        resendCooldownEnd,
        setResendCooldownEnd,
        // Verification wrappers
        sendVerificationCode,
        confirmVerificationCode,
        // Navigation
        goToSignUp,
        goToSignIn,
        goToVerifyCode,
        goToEnterUserPin,
      }}>
      {children}
    </LoginProcess.Provider>
  );
};

export default LoginProcessProvider;
