import {useEffect, useRef, useState} from 'react';
import dynamic from 'next/dynamic';
import {useRouter} from 'next/router';

import {UserStatus} from '@/graphql/__generated__/graphql';
import AuthenticationLayout from '@/layout/authentication/AuthenticationLayout';

import {getUserStatus} from '@/graphql/queries';
import {useQuery} from '@apollo/client';

import CountriesInfoContextProvider from '@/contexts/CountriesInfoContext';
import LoginProcessProvider from '@/contexts/LoginProcessContext';
import {useUser} from '@/contexts/UserContext';
import {useAuthenticationServices} from '@/hooks/login/useAuthenticationServices';
import {useUserEmail} from '@/hooks/user';
import {pinService} from '@/services/pin/PinService';

import PageInitiator from '@/components/PageInitiator';
import Splash from '@/components/Splash/Splash';

import type {ISOCountryCode, LoginSteps} from '@/types/business';
import type {ComponentProps, ComponentType, ReactElement, ReactNode} from 'react';

type StepType = ComponentType<ComponentProps<any>> | (() => ReactNode);

// Dynamic Steps
const DynamicEnterPhoneOrEmail = dynamic(
  () => import('@/components/auth/signin/EnterPhoneOrEmail/EnterPhoneOrEmail'),
);
const DynamicUserNotFound = dynamic(() => import('@/components/auth/signin/UserNotFound/UserNotFound'));
const DynamicVerifyEmailCode = dynamic(
  () => import('@/components/auth/signin/EnterVerificationCode/EnterVerificationCode'),
);
const DynamicEnterUserPin = dynamic(() => import('@/components/auth/signin/EnterUserPin/EnterUserPin'));
const DynamicUserNotLinked = dynamic(() => import('@/components/auth/signin/UserNotLinked/UserNotLinked'));

const StepToComponent = {
  enterPhoneOrEmail: DynamicEnterPhoneOrEmail,
  userNotFound: DynamicUserNotFound,
  userNotLinked: DynamicUserNotLinked,
  verifyCode: DynamicVerifyEmailCode,
  enterUserPin: DynamicEnterUserPin,
  blocked: () => null,
};

const Login = () => {
  const router = useRouter();

  const [countryCode, setCountryCode] = useState<ISOCountryCode>();
  const countyCodeDetectionAttempted = useRef(false);
  const {userService, authService} = useAuthenticationServices();
  const email = useUserEmail() as string;

  const {fbUser, setFBUserOnce, fetchAndSetUser} = useUser();
  const isUserDataAvailable = fbUser && !fbUser.isAnonymous && !!fbUser.email;

  // Here we can use "getLastIdentifier" flag as a proxy for "isUserLoggedIn",
  // which is async and takes 1-2 seconds. When the user logs out,
  // their last identifier gets cleared. Identifier exists == user logged in.
  const isUserLoggedIn = authService.getLastIdentifier();
  const isPinTimedOut = pinService.isPinTimedOut();

  const [onBack, setBackHandler] = useState<() => void>(() => () => {});
  const [loginStep, setLoginStep] = useState<LoginSteps>(
    isUserLoggedIn && isPinTimedOut ? 'enterUserPin' : 'enterPhoneOrEmail',
  );

  useEffect(() => {
    if (isUserLoggedIn && !isPinTimedOut) {
      router.push('/home');
    }
  }, [isPinTimedOut, isUserLoggedIn, router]);

  useEffect(() => {
    if (countyCodeDetectionAttempted.current === true) {
      return;
    }

    const fetchLocation = async () => {
      const location = await userService.getUserLocationAndIP();
      setCountryCode(location?.geo?.country);
    };

    fetchLocation();
    countyCodeDetectionAttempted.current = true;
  }, [countryCode, userService]);

  const onError = () => setLoginStep('enterPhoneOrEmail');

  const {loading: isAuthLoading} = useQuery(getUserStatus, {
    variables: {email},
    skip: !isUserDataAvailable,
    fetchPolicy: 'cache-and-network',
    onError,
    onCompleted: async data => {
      // Resume sign up if necessary...
      const status = data.getUserStatus;
      if (status === UserStatus.Created) {
        return router.push('/signup/pincode');
      }
      if (status === UserStatus.PasswordSet) {
        return router.push('/signup/info');
      }

      if (isPinTimedOut) {
        setLoginStep('enterUserPin');
      }

      // Prefetch user data to be used after login...
      fetchAndSetUser().catch(onError);
    },
  });

  if (!setFBUserOnce) {
    return <Splash />;
  }

  const Step: StepType = StepToComponent[loginStep] || null;
  const isFirstStep = loginStep === 'enterPhoneOrEmail';
  const isLeftAligned = isFirstStep;
  const routesWithoutBackStep = ['enterPhoneOrEmail'];
  const isGoBackEnabled = loginStep !== undefined && !routesWithoutBackStep.includes(loginStep);

  return (
    <LoginProcessProvider loginStep={loginStep} setLoginStep={setLoginStep}>
      <AuthenticationLayout
        onBack={onBack}
        isLeftAligned={isLeftAligned}
        isBackEnabled={isGoBackEnabled}
        pageTitle="Login | Ka. Web App">
        <Step
          setBackHandler={setBackHandler}
          countryCode={countryCode}
          isLoading={isAuthLoading}
          flow="login"
        />
      </AuthenticationLayout>
    </LoginProcessProvider>
  );
};

Login.getLayout = function getLayout(page: ReactElement) {
  return (
    <PageInitiator {...page.props}>
      <CountriesInfoContextProvider>{page}</CountriesInfoContextProvider>
    </PageInitiator>
  );
};

export default Login;
